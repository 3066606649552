import React from 'react'

import './style.scss'

import Navigation from '../components/navigation'

import withLayout from '../components/layout'
import publications from '../i18n/translations/nl/publications'
import Publication from '../components/publication'

const PublicationsPage = ({ pageContext }) => {
  const items = Object.keys(publications.items)

  return (
    <>
      <header className="small_header">
        <div className="inline">
          <Navigation />
        </div>
      </header>
      <main>
        <section className="info inline">
          {items &&
            items.map((publication, i) => (
              <Publication
                key={i}
                trans={`publications.items.${publication}`}
              />
            ))}
        </section>
      </main>
    </>
  )
}

const customProps = {
  localeKey: 'publications', // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(PublicationsPage)
