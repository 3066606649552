import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ExternalLink from '../shared/external-link'

const Publication = ({ trans }) => {
  const intl = useIntl()

  return (
    <article>
      <h2>
        <FormattedMessage id={`${trans}.title`} />
      </h2>

      {intl.messages[`${trans}.publisher`] && (
        <b>
          <FormattedMessage id={`${trans}.publisher`} />
        </b>
      )}
      {intl.messages[`${trans}.description`] && (
        <p>
          <FormattedMessage id={`${trans}.description`} />
        </p>
      )}

      {intl.messages[`${trans}.isbn`] && (
        <p>
          <FormattedMessage id="isbn" />:{' '}
          <FormattedMessage id={`${trans}.isbn`} />
        </p>
      )}

      {intl.messages[`${trans}.link`] && (
        <p>
          <ExternalLink href={intl.messages[`${trans}.link`]}>
            <FormattedMessage id={`${trans}.title`} />
          </ExternalLink>
        </p>
      )}
    </article>
  )
}
export default Publication
